import React from "react";

export const CallIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
    clipRule="evenodd"
  >
    <path
      fill="#fff"
      d="M18.384 22.779a1.19 1.19 0 001.107.145 1.16 1.16 0 00.724-.84C21.084 18 23.192 7.663 23.983 3.948a.78.78 0 00-.26-.758.8.8 0 00-.797-.14C18.733 4.602 5.82 9.447.542 11.4a.827.827 0 00-.542.799c.012.354.25.661.593.764 2.367.708 5.474 1.693 5.474 1.693s1.452 4.385 2.209 6.615c.095.28.314.5.603.576a.866.866 0 00.811-.207l3.096-2.923s3.572 2.619 5.598 4.062zm-11.01-8.677l1.679 5.538.373-3.507 10.185-9.186a.277.277 0 00.033-.377.284.284 0 00-.376-.064L7.374 14.102z"
    ></path>
  </svg>
);
export const EmailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.5"
    height="16.377"
    viewBox="0 0 21.5 16.377"
  >
    <g id="email-line" transform="translate(0 -61)">
      <g id="Group_34841" data-name="Group 34841" transform="translate(0 61)">
        <path
          id="Path_36827"
          data-name="Path 36827"
          d="M19.61,61H1.89A1.893,1.893,0,0,0,0,62.89v12.6a1.893,1.893,0,0,0,1.89,1.89H19.61a1.893,1.893,0,0,0,1.89-1.89V62.89A1.893,1.893,0,0,0,19.61,61Zm-.261,1.26L10.79,70.819,2.157,62.26ZM1.26,75.226V63.144l6.067,6.015Zm.891.891,6.071-6.071,2.127,2.109a.63.63,0,0,0,.889,0l2.074-2.074,6.038,6.038Zm18.09-.891L14.2,69.188l6.038-6.038Z"
          transform="translate(0 -61)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
export const AddressIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="21.013"
    viewBox="0 0 17 21.013"
  >
    <g id="location-3" transform="translate(-48.886)">
      <g id="Group_34838" data-name="Group 34838" transform="translate(48.886)">
        <g id="Group_34837" data-name="Group 34837" transform="translate(0)">
          <path
            id="Path_36825"
            data-name="Path 36825"
            d="M64.361,3.653a8.484,8.484,0,0,0-13.949,0,8.485,8.485,0,0,0-.988,7.818A6.683,6.683,0,0,0,50.655,13.5l6.172,7.249a.734.734,0,0,0,1.119,0l6.17-7.247a6.689,6.689,0,0,0,1.231-2.031A8.487,8.487,0,0,0,64.361,3.653Zm-.39,7.308A5.241,5.241,0,0,1,63,12.549l0,0-5.612,6.592-5.616-6.6a5.244,5.244,0,0,1-.971-1.592,7.019,7.019,0,0,1,.82-6.468,7.015,7.015,0,0,1,11.532,0A7.02,7.02,0,0,1,63.971,10.962Z"
            transform="translate(-48.886)"
            fill="#fff"
          />
        </g>
      </g>
      <g
        id="Group_34840"
        data-name="Group 34840"
        transform="translate(53.272 4.359)"
      >
        <g id="Group_34839" data-name="Group 34839">
          <path
            id="Path_36826"
            data-name="Path 36826"
            d="M159.868,106.219a4.114,4.114,0,1,0,4.114,4.114A4.119,4.119,0,0,0,159.868,106.219Zm0,6.759a2.645,2.645,0,1,1,2.645-2.645A2.648,2.648,0,0,1,159.868,112.978Z"
            transform="translate(-155.754 -106.219)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
  >
    <path
      fill="#fff"
      d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
    />
  </svg>
);
export const GithubIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
  >
    <path
      fill="#fff"
      d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"
    />
  </svg>
);
export const LinkedinIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
  >
    <path
      fill="#fff"
      d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
    />
  </svg>
);
export const TwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
  >
    <path
      fill="#fff"
      d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
    />
  </svg>
);
export const FacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
  >
    <path
      fill="#fff"
      d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
    />
  </svg>
);
